import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import Swal from "sweetalert2";

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfFormDeleteAccountComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private password: string = null;
  private formError: any = {};
  private working = false;
  private passwordFieldType: "password" | "text" = "password";
  private passwordObfuscated = true;
  private passwordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";

  private deleteAccount() {
    this.formError = [];
    this.working = true;
    this.service
      .deleteAccount({password: this.password})
      .then((response: any) => {
        Swal.fire({
          title: this.$t("df-form-delete-account.modal.title").toString(),
          text: this.$t("df-form-delete-account.modal.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-form-delete-account.modal.ok").toString(),
        }).then((result) => {
          if (result.value) {
            this.$emit("deleteAccount");
            this.working = false;
          }
        });
      })
      .catch((error: any) => {
        this.working = false;
        if (error.response.data.errors) {
          this.formError = [];
          error.response.data.errors.map((errorData: any) => {
            this.formError[errorData.field] = `${errorData.objectName}-${errorData.code}`;
            console.log(`${errorData.objectName}-${errorData.code}`, this.$t(`${errorData.objectName}-${errorData.code}`));
          });
        } else {
          Utils.defaultApiErrorHandler(error, this);
        }
      });
  }

  private changePasswordFieldType() {
    this.passwordObfuscated = !this.passwordObfuscated;
    this.passwordObfuscatedIcon = this.passwordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.passwordFieldType = this.passwordFieldType == "text" ? "password" : "text";
  }
}
